import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/LayoutComponent"
import { Col, Container, Row } from "react-bootstrap"
import ImageGallery from "react-image-gallery"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"
import netlifyStyle from "../../site/settings/style.json"

//! Slider is not responsive fix later

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        completion_date
        contractor
        featuredimage
        location
        description

        image_2
        image_3
        image_4
      }
      html
      fields {
        slug
      }
    }
  }
`

const linkStyle = {
  border: "none",
  color: netlifyStyle.brand_color,
}

const rootContainerStyle = {
  //minHeight: "80vh",
  marginTop: "2rem",
}

const carouselItemStyle = {
  padding: "10px",
  maxHeight: "300px",
}

const Project = props => {
  return (
    <Layout>
      <SEO
        title={props.data.markdownRemark.frontmatter.title}
        description={
          props.data.markdownRemark.frontmatter.description || "nothin’"
        }
        image={props.data.markdownRemark.frontmatter.featuredimage}
        pathname={props.data.markdownRemark.fields.slug}
        article
      />
      <Container
        style={rootContainerStyle}
        className="d-flex flex-column justify-content-start align-items-center "
      >
        <h1
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          style={{ color: netlifyStyle.brand_color }}
          className="text-center mt-2"
        >
          {props.data.markdownRemark.frontmatter.title}
        </h1>
        <h4
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="text-center mt-2"
        >
          {props.data.markdownRemark.frontmatter.location}
        </h4>
        <ImageGallery
          items={[
            {
              original: props.data.markdownRemark.frontmatter.featuredimage,
              thumbnail: props.data.markdownRemark.frontmatter.featuredimage,
            },
            {
              original: props.data.markdownRemark.frontmatter.image_2,
              thumbnail: props.data.markdownRemark.frontmatter.image_2,
            },
            {
              original: props.data.markdownRemark.frontmatter.image_3,
              thumbnail: props.data.markdownRemark.frontmatter.image_3,
            },
            {
              original: props.data.markdownRemark.frontmatter.image_4,
              thumbnail: props.data.markdownRemark.frontmatter.image_4,
            },
          ]}
          thumbnailLoading={"lazy"}
          autoPlay={true}
        />
        <Row className="d-flex jusify-content-around my-2">
          <Col className="text-center">
            <h5 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
              Contractor: {props.data.markdownRemark.frontmatter.contractor}
            </h5>
          </Col>
          <Col className="text-center">
            <h5 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
              Completion Date:{" "}
              {props.data.markdownRemark.frontmatter.completion_date}
            </h5>
          </Col>
        </Row>

        <div
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        ></div>
        <Link
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          style={linkStyle}
          to="/project-gallery"
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Back To Project Gallery
        </Link>
      </Container>
    </Layout>
  )
}

export default Project

/*  <CustomImg
            markdownLink={
              props.data.markdownRemark.frontmatter.projectgallery.image_1
            }
          ></CustomImg>

           <div
                style={{
                  background: `url("${props.data.markdownRemark.frontmatter.image_3}") no-repeat center center/cover`,
                  height: "25rem",
                }}
              ></div>
*/
